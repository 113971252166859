<template>
  <div class="about">
    <div class="row">
      <div class="column">
        <p class="about-p-main">
          As it always happens, it all started with the projects for fun. This lasted 
          until we have realized that these projects could generate revenue. <span class="company">Strangebit</span>
          was founded in 2019 as a software development company. The founder, Dmitriy Kuptsov, 
          considers himself an enthusiast, coder, and he is passionate about everything that relates to technology. Our company has a 
          background in DevOps (we like to configure new instances of Linux OS in the 
          cloud using bash and Python, but we also have experience with Ansible toolset). 
          We do also have the background in network protocol design and implementation. 
          For example, we have developed several network security protocols, including a 
          VPN solution and a more exotic Host Identity Protocol. But we do like to 
          decipher stuff as well. For instance, we also have experience with tracing and 
          understanding every bit of information in the Digital Video Broadcast (or DVB-C) 
          signal and streaming it over the IP network.  And, finally, the most recent effort 
          relates to a security solution for the web application. Thus, our recent effort 
          relates to the integration of RSA dongle with a web portal for authentication and 
          authorization. We do also build web portals and web interfaces using the latest 
          web development frameworks. For example, we have several successful projects 
          dealing with the web interfaces tailored for configuration of various Linux daemons.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <p>
          Since we try our best in figuring out 
          details as much as we can, and since often even a change in one bit leads to catastrophic
          consequences, we named the company <span class="company">Strangebit</span> just to 
          underline that we question every bit in the code.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <p>
          Our current development stack includes Python3, Vue.js and MySQL. And,
          of course, everything we do spins on Linux servers. We have experience
          in writing not so simple SQL queries with tons of joins, unions and subqueries.
          We love working with Python Flask, and it is at the core of every web project 
          we build. We love it for its simplicity, modularity and extensibility. We do
          also utilize Nginx as our Internet facing web server: It is fast and has
          lots of goodies builtin. Given the stack that we use, it might seem that we 
          mostly create web services, however,
          we do not limit ourselves to these type of projects - we also do low level 
          programming as you can find out if you will click <router-link to="/projects">here</router-link>.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <p>
	We are happy to announce that we have started the blog on Machine Learning. Interested?
	Then visit it <a href="https://ml.strangebit.io">by following this link</a>.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <p>
        Interested in our company? Please continue reading more about the projects
        we have implemented, or otherwise, are currently working on. But if you 
        want to say <i>hi</i> or need help with some project you can drop us 
        a message <router-link to="/contact">here</router-link>.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <p>
          Badges:
        </p>
        <p>
          <img src="@/assets/comptia.png" style="width: 120px"/>
          <img src="@/assets/ccent.jpg" style="width: 120px"/>
          <img src="@/assets/linux.png" style="width: 120px"/>
          <img src="@/assets/data+.png" style="width: 120px"/>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

@media (max-width:700px) {
  .about {
    width: 100%;
  }
}

@media (min-width:700px) {
  .about {
    width: 80%;
    margin-left: 10%;
  }
}

.company {
    font-weight: bold;
}

p {
    font-family: Times New Roman;
    font-size: 14pt;
    text-align: justify;
}

.single {
    margin-right: 5%;
    margin-left: 5%;
}

.column {
    float: left;
}

.row {
  width: 100%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

p {
    margin-left: 10pt;
    margin-right: 10pt;
    margin-top: 1pt;
}

.about-p-main {
    font-family: Times New Roman;
    font-size: 14pt;
    /*margin-left: 10pt;
    margin-right: 10pt;*/
    margin-top: 1pt;
}

.about-p-main:first-letter {
    text-transform:capitalize;
    color: black;
    font-size: 400%;
    font-weight: bold;
    display: block;
    float: left;
    margin-right: 5pt;
}
</style>
